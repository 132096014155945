import "./style.css";
import { Map, View } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON.js";
import { fromLonLat } from "ol/proj";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Overlay from "ol/Overlay.js";
import RegularShape from "ol/style/RegularShape";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";

function checkIfVoted(feature) {
  for (let key in localStorage) {
    if (localStorage.hasOwnProperty(feature.get("LS ID"))) {
      return "#06d6a0";
    }
    return "#001524";
  }
}

function getPoint(feature) {
  const pointStyle = new Style({
    image: new RegularShape({
      points: 3,
      radius: 12,
      angle: 0,
      fill: new Fill({
        color: "#eeeeeeaa",
      }),
      stroke: new Stroke({
        color: checkIfVoted(feature),
        width: 3,
      }),
    }),
  });
  return pointStyle;
}

const container = document.getElementById("popup");
const content = document.getElementById("popup-content");
const voteButton = document.getElementById("vote-button");

const overlay = new Overlay({
  element: container,
  autoPan: true,
  autoPanAnimation: {
    duration: 250,
  }
});

const summitsLayer = new VectorLayer({
  source: new VectorSource({
    url: "nyaToppar.json",
    format: new GeoJSON(),
  }),
  style: function (feature) {
    return getPoint(feature);
  },
});

const map = new Map({
  target: "map",
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
  ],
  view: new View({
    center: fromLonLat([17.723377, 63.43162]),
    zoom: 5.5,
  }),
});

map.addLayer(summitsLayer);
map.addOverlay(overlay);

function disableVoteButton(button) {
  if (localStorage.hasOwnProperty(button.dataset.id)) {
    button.disabled = true;
  } else {
    button.disabled = false;
  }
}

voteButton.addEventListener("click", function (evt) {
  fetch(
    `https://sotavote.azurewebsites.net/api/summitvote?id=${this.dataset.id}`,
    {
      method: "POST",
    }
  )
    .then((response) => response.json()) // parse the response as JSON
    .then((json) => {
      content.innerHTML =
        "<p>" + json.votes + " Röst" + (json.votes != 1 ? "er" : "") + "</p>";
      localStorage.setItem(this.dataset.id, true);

      const layer = map.getLayers().getArray()[1];
      layer.getSource().changed();
      disableVoteButton(this);
    });
});

map.on("click", function (evt) {
  const summitsLayerInfo = map.getFeaturesAtPixel(evt.pixel, {
    layerFilter: (layer) => layer === summitsLayer,
  });

  if (summitsLayerInfo.length > 0) {
    overlay.setPosition(summitsLayerInfo[0].getGeometry().getCoordinates());
    content.innerHTML = "<p>Laddar...</p>";
    voteButton.style.display = "none";

    fetch(
      `https://sotavote.azurewebsites.net/api/summitvote?id=${summitsLayerInfo[0].get(
        "LS ID"
      )}`
    )
      .then((response) => response.json()) // parse the response as JSON
      .then((json) => {
        console.log(json);
          content.innerHTML =
            "<p>" + json.votes + " Röst" + (json.votes != 1 ? "er" : "") + "</p>";
        voteButton.setAttribute("data-id", json.id);
        voteButton.style.display = "block";

        disableVoteButton(voteButton);
      })
      .catch((error) => console.error(error)); // handle any errors that occur during the request
  } else {
    overlay.setPosition(undefined);
  }
});
